<template>
    <modal @close="closeModal" :show="modalOpen">
        <form @submit.prevent="updatePost">
            <div class="flex justify-between ">
                <h6 class="font-semibold text-center text-primary">
                    {{ $t('Edit Post') }}
                </h6>
            </div>

            <div class="flex flex-row mb-4">
                <div class="w-full ml-3">
                    <div class="flex flex-row">
                        <div class="w-full ">
                            <InputLabel for="title" :value="$t('Name')" class="mb-4 mr-4" />
                            <textarea
                            class="block w-full p-4 min-h-24 text-sm resize-none border border-[#919EAB33] rounded-lg placeholder:text-disabled text-primary"
                            :placeholder="$t('What is going on in your corner of the industry...?')" v-model="form.text" @paste="handlePaste"
                            @change="handleChange">
									</textarea>
                            <InputError class="mt-2" :message="form.errors?.title" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row ">
                <div class="w-full ml-3">
                    <div class="flex flex-row mt-24 mb-4">
                        <div class="w-full mr-3">
                            <InputLabel for="filepond--browser-9iez9coiy" value="Image" class="mb-4 mr-4" />
                            <FileUploader ref="fileUploaderRef" @update:modelValue="fileUploaderValue" :allowMultipleFiles="true" :allowReorder="true"  id="adasjkdzxhcaoqoiwui0000000123567" />
                            <InputError class="mt-2" :message="form.errors?.file" />
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="post.media && post.media.length > 0">
               <draggable v-model="imageRef" direction="vertical" :scroll-sensitivity="1900"
                     :component-data="{name:'fade'}" animation="150" @end="swapImage" :item-key="element => element ? element.id : undefined">
                  <template #item="{element}">
                     <div v-if="element" class="inline-flex ml-4 mb-4 mt-4">
                        <div class="flex items-center justify-around">
                           <div class="relative">
                                 <!-- <img class="rounded-md h-28 w-32 object-cover relative" :src="element?.url" alt=""> -->
                                 <component :is="mediaType(element)" :src="mediaTypeUrl(element)" alt="File Media"
                                class="rounded-md h-28 w-32 object-cover relative" />
                                 <!-- <span @click.prevent="handleRemovePicture(element)" class="absolute top-1 right-1 cursor-pointer">
                                 <RemoveIcon class="w-8 h-8 text-white bg-black rounded-full hover:-translate-y-1 hover:scale-110 transition ease-in-out delay-150 fill-inherit"/>
                                 </span> -->
                           </div>
                        </div>
                     </div>
                  </template>
               </draggable>
            </template>
            <div
                class=" flex justify-end mr-3 pb-3 text-gray-500 font-semibold  text-lm font-['Montserrat'] leading-snug space-x-2">
                <GenericButton type="button" @click="closeModal" :text="$t('Discard')" theme="inherit-outlined"
                    size="medium"
                    class="border border-transparent bg-secondary-12 text-inherit hover:bg-secondary-10" />
                <GenericButton type="submit" size="medium" theme="inherit-outlined"
                    class="cursor-pointer hover:text-white hover:bg-secondary-16 " :text="$t('Save Changes')" />
            </div>
        </form>
    </modal>
</template>

<script setup>
import axios from "axios";
import Swal from 'sweetalert2';
import { ref, toRef, watch } from "vue";
import draggable from 'vuedraggable';

import FileUploader from "@/Components/FileUploader.vue";
import GenericButton from "@/Components/GenericButton.vue";
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import Modal from "@/Components/Modal.vue";



const emit = defineEmits(['update:toggleModal']);

const path = ref(import.meta.env.VITE_APP_URL);
const linkPreviews = ref([]);


const props = defineProps({
    modalOpen: Boolean,
    updateRoute: String,
    title: String,
    category: String,
    editObject: Object,
    post: Object
});

const form = ref({
    text: props.post.content,
    files: [],
    id:props.post.id
});
const imageRef = toRef(props.post, 'media')

const swapImage = async (evt) => {
   /*
      choose newIndex because after the swapping it will call this method
      so the new index will get the correct image id
   */
   const imageId = imageRef.value[evt.newIndex].id;
   const newPosition = evt.newIndex;

   await axios.patch(route('update.post.image', {
         position: newPosition + 1,
         imageId: imageId,
         post_id: props.post.id
      }))
       .then((response) => {
           Swal.fire({
               icon: 'success',
               title: 'Sorted successfully!',
               showConfirmButton: false,
               timer: 1500
           });
       })
       .catch((error) => {
           console.error('Error updating image position:', error);
           Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Failed to sort images!',
           });
       });
};

const updateLinkPreviews = () => {
    const text = form.value.text;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex) || [];
    const currentUrlsSet = new Set(urls);

    // Remove links from linkPreviews
    linkPreviews.value = linkPreviews.value.filter(preview => currentUrlsSet.has(preview.original));

    // Check if there are new URLs to process
    if (urls.length === 0) {
        return;
    }

    // Set for new URLs that need previews
    const newUrlsSet = new Set(urls);

    // Remove URLs that already have previews
    linkPreviews.value.forEach(preview => newUrlsSet.delete(preview.original));

    if (newUrlsSet.size > 0) {
        loading.value = true;
        const newUrls = Array.from(newUrlsSet);

        axios.put(route('feed.load_url_previews'), {urls: newUrls})
            .then(response => {
                const handledUrls = response.data.urls || [];
                handledUrls.forEach(url => {
                    if (!linkPreviews.value.find(preview => preview.original === url.original)) {
                        linkPreviews.value.push(url);
                    }
                });
            })
            .catch(err => {
                console.log('Error:', err);
            })
            .finally(() => {
                loading.value = false;
            });
    }
};



const handleChange = () => {
    setTimeout(() => {
        updateLinkPreviews();
    }, 500);
};

const handlePaste = () => {
    setTimeout(() => {
        updateLinkPreviews();
    }, 100);
};



const fileUploaderValue = (files) => {
    form.value.files = files;
}
const fileUploaderRef = ref(null);
const clearFilesHandler = () => {
    if (fileUploaderRef.value) {
        fileUploaderRef.value.clearFiles();
    }
};



const updatePost = async () => {

    let formData = new FormData();
    formData.append('text', form.value.text);
    formData.append('id', form.value.id);


    if (form.value.files) {
        form.value.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });
    }

    try {
        const response = await axios.post(route('posts.update'), formData);
        emit("update:toggleModal", false);
        form.value.text = '';
        form.value.files = [];
        clearFilesHandler();
        emit('update:data', response.data.posts);
    } catch (error) {
        console.error('Error:', error);
    }
}

const mediaType = (media) => {
    return media.type === 'image' ? 'img' : 'iframe';
}
const mediaTypeUrl = (media) => {
    return media.url ?? media.embed;
}

watch(() => props.editObject, (newValue) => {
    form.title = newValue?.title;
    form.text = newValue?.text;
})

const closeModal = () => {
    emit("update:toggleModal");
    form.clearErrors();
}
</script>

<style scoped>
.v-menu__content {
    display: flex;
    flex-wrap: wrap;
    max-height: none;
    min-width: auto;
}
</style>
